import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import {
  GetLocationGroupsRequest, GetLocationGroupsResponse,
  GetUSCitiesResponse,
  GetUSDMAResponse,
  GetUSPostalCodesResponse,
  GetUSStatesResponse,
} from '../types/locations'

export class LocationsService extends ApiService {
  getUSStatesList(): AxiosPromise<GetUSStatesResponse> {
    return this.request({
      url: 'api/location/US/Region/states',
      method: 'GET',
    })
  }

  getUSCitiesList(searchQuery: string): AxiosPromise<GetUSCitiesResponse> {
    return this.request({
      url: `api/location/US/City/${searchQuery}`,

      method: 'GET',
    })
  }

  getUSDMAList(searchQuery: string): AxiosPromise<GetUSDMAResponse> {
    return this.request({
      url: `api/location/US/DMA/${searchQuery}`,
      method: 'GET',
    })
  }

  getUSPostalCodesList(codes: string[]): AxiosPromise<GetUSPostalCodesResponse> {
    return this.request({
      url: 'api/location/US/postalcodes',
      data: codes,
      method: 'POST',
    })
  }

  getLocationGroups({ marketerId, accountId }: GetLocationGroupsRequest): AxiosPromise<GetLocationGroupsResponse> {
    return this.request({
      url: 'api/audience',
      method: 'GET',
      params: {
        marketerId,
        accountId,
        audienceType: 'Location',
      },
    })
  }
}
