import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import { GetInventoryListsResponse } from '../types/inventoryList'

export class InventoryListService extends ApiService {
  getInventoryList(marketerId: number): AxiosPromise<GetInventoryListsResponse> {
    return this.request({
      url: `api/inventoryList/search?marketerId=${marketerId}`,
      method: 'GET',
    })
  }
}
