export type InventoryList = {
  id: number
  name: string
  advertiserId: number
  marketerId: number
  advertiserName: string
  marketerName: string
  internal: boolean
  domainsFileName: string
  domainsFileURL: string
  domainsDescription: string
  appsFileName: string | null
  appsFileURL: string | null
  appsDescription: string | null
  createdDate: string
  inventoryListType: InventoryListType
  totalRecordsCount: number
  uniqueRecordsCount: number
  inventoryListStatusId: number
  inventoryListStatus: string
}

export type GetInventoryListsResponse = InventoryList[]

export enum InventoryListType {
  Blacklist = 1,
  Whitelist = 2,
}
