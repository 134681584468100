import { AxiosPromise } from 'axios'

import { ApiService } from '@lasso/api-shared'

import {
  AudiencesSearchRequest,
  GetAudiencesResponse,
  GetBrandCodesRequest, GetBrandCodesResponse, GetBrandSafetyListRequest, GetBrandSafetyListResponse,
  GetBrandsRequest,
  GetBrandsResponse, GetDealsListRequest, GetDealsListResponse,
} from '../types/audiences'

export class AudiencesService extends ApiService {
  async getAudiences({
    keyword,
    marketerId,
    accountId,
    adGroupChannelId,
    pageNumber,
    pageSize,
    audienceType,
    audienceTarget,
    sortName,
  }: AudiencesSearchRequest): Promise<GetAudiencesResponse> {
    return (await this.request({
      url: 'api/audience/search',
      method: 'POST',
      data: {
        pager: {
          pageNumber: pageNumber ?? 1,
          pageSize: pageSize ?? 100,
          sortDescending: true,
          sortName: sortName ?? 'Name',
        },
        filter: {
          audienceType: audienceType ?? '',
          audienceTarget,
          marketerId,
          isAudiencePage: false,
          accountId,
          keyword,
          adGroupChannelId,

          showInternal: null,
          isPublicFilter: null,
          id: null,
          hasMeta: null,
          channelId: null,
          version: 'V2',
        },
      },
    })).data
  }

  getBrands({ marketerId, advertiserId, searchQuery, ids, pager }: GetBrandsRequest): AxiosPromise<GetBrandsResponse> {
    const idFilder = ids ? { id: { in: ids } } : {}
    const filter = {
      ...idFilder,
      name: { like: searchQuery },
      domain: null,
      advertiserId: { advertiserId },
      marketerId: { marketerId },
    }
    return this.request({
      url: 'api/audience/search-concept-groups',
      method: 'POST',
      data: {
        pager,
        filter,
      },
    })
  }

  getBrandCodes({
    brandId,
    page,
    pageSize,
    searchQuery,
  }: GetBrandCodesRequest): AxiosPromise<GetBrandCodesResponse> {
    return this.request({
      url: `api/audience/concepts/${brandId}`,
      method: 'POST',
      data: {
        pager: {
          page,
          pageSize,
        },
        filter: {
          '@search': searchQuery,
        },
      },
    })
  }

  getBrandSafetyList({ marketerId, accountId }: GetBrandSafetyListRequest): AxiosPromise<GetBrandSafetyListResponse> {
    return this.request({
      url: 'api/audience',
      method: 'GET',
      params: {
        marketerId,
        accountId,
        audienceType: 'Brand Safety',
      },
    })
  }

  getDealsList({ channelId, marketerId, advertiserId }: GetDealsListRequest): AxiosPromise<GetDealsListResponse> {
    return this.request({
      url: 'api/Segments/connectedtvinventory',
      method: 'GET',
      params: {
        channelId,
        marketerId,
        advertiserId,
      },
    })
  }
}
